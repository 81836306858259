<template src="./index.html">

</template>

<script>
import CourseSemester from '@/views/admin/category/course-semester/CourseSemester'
import {
  BBreadcrumb,
  BButton,
  BCard,
  BContainer,
  BCol,
  BRow,
  BOverlay
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'ConfigAutoCreateStudentCode',
  components: {
    CourseSemester,
    BBreadcrumb,
    BButton,
    BCard,
    BContainer, BCol, BRow, BOverlay,
    draggable
  },

  data() {
    return {
      loading: true,
      dataCourse: {},
      criteria: [], // Chứa danh sách các tiêu chí từ DB
      store: [],
      displayItems: []
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'configStudentCode/dataLists',
    }),

  },
  async created() {
    // init data FROM API
    this.loading = true
    try {
      await this.getData()
      if (this.dataLists.length > 0) {
        this.criteria = this.dataLists.filter(item => item.active === 1)
        this.store = this.dataLists.filter(item => item.active === 0 && item.code !== 'COUNT')
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.loading = false
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      getData: 'configStudentCode/getData',
      update: 'configStudentCode/updateData'
    }),

    onDragEnd(event) {
      // Cập nhật lại orderNo cho các đối tượng
      this.criteria.forEach((item, index) => {
        item.orderNo = index + 1
      })
      this.criteria.sort((a, b) => {
        if (a.orderNo < b.orderNo) return -1
        if (a.orderNo > b.orderNo) return 1
        return 0
      })
    },

    async onSave(){
      this.store.forEach(item => {
        item.active = 0
      })

      this.criteria.forEach(item => {
        item.active = 1
      })

      const result = await this.update([...this.criteria, ...this.store])
      if (result) {
        this.showToast('Cập nhật thành công!', 'CheckIcon', 'success', '')
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning', '')
      }
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },

}
</script>

<style>
.drag-item {
  display: inline-block;
  margin-right: 10px; /* margin giữa các thành phần */
  border: 1px solid grey;
  padding: 5px;
  cursor: pointer;
  background-color: gray;
  color: white;
  border-radius: 5px;
}

.drag-item:not(:has(input)) {
  /* Đặt các thuộc tính CSS cho các thẻ div khác */
  padding: 8px;
}

.border {
  padding: 20px;
  border: 1px solid grey;
}

p.text-title {
  color: red;
  font-weight: bold;
  font-size: 16px;
}
.item {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  margin: 5px;
  padding: 10px;
}
</style>
